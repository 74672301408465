<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도면 등록</h4>
            <span>도면 정보를 등록합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->

        <div class="row sub-layout mt-0 mt-sm-3">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-12 board-write">
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">타입</span>
                    <div class="form-check form-check-inline ml-1">
                      <input class="form-check-input" type="radio" name="typeRadioOptions" id="typeRadio1"
                             value="ㅡ" v-model="type">
                      <label class="form-check-label" for="typeRadio1">ㅡ</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="typeRadioOptions" id="typeRadio2"
                             value="ㄱ" v-model="type">
                      <label class="form-check-label" for="typeRadio2">ㄱ</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="typeRadioOptions" id="typeRadio3"
                             value="ㄷ" v-model="type">
                      <label class="form-check-label" for="typeRadio3">ㄷ</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="typeRadioOptions" id="typeRadio4"
                             value="ㅁ" v-model="type">
                      <label class="form-check-label" for="typeRadio4">ㅁ</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="typeRadioOptions" id="typeRadio5"
                             value="ㅡ+ㄱ" v-model="type">
                      <label class="form-check-label" for="typeRadio5">ㅡ+ㄱ</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="typeRadioOptions" id="typeRadio6"
                             value="11" v-model="type">
                      <label class="form-check-label" for="typeRadio6">11</label>
                    </div>
                  </div>
                </div>

                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">치수(1개 필수)</span>
                    <input inputmode="numeric" type="text" class="form-control" placeholder="*치수1" v-model.trim="size1"/>
                    <input inputmode="numeric" type="text" class="form-control" placeholder="치수2" v-model.trim="size2"/>
                    <input inputmode="numeric" type="text" class="form-control" placeholder="치수3" v-model.trim="size3"/>
                    <input inputmode="numeric" type="text" class="form-control" placeholder="치수4" v-model.trim="size4"/>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">서랍</span>
                    <div class="form-check form-check-inline ml-1">
                      <input class="form-check-input" type="radio" name="drawerRadioOptions" id="drawerRadio1"
                             value="없음" v-model="drawer">
                      <label class="form-check-label" for="drawerRadio1">없음</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="drawerRadioOptions" id="drawerRadio2"
                             value="2단" v-model="drawer">
                      <label class="form-check-label" for="drawerRadio2">2단</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="drawerRadioOptions" id="drawerRadio3"
                             value="3단" v-model="drawer">
                      <label class="form-check-label" for="drawerRadio3">3단</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="drawerRadioOptions" id="drawerRadio4"
                             value="4단" v-model="drawer">
                      <label class="form-check-label" for="drawerRadio4">4단</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="drawerRadioOptions" id="drawerRadio5"
                             value="5단" v-model="drawer">
                      <label class="form-check-label" for="drawerRadio5">5단</label>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">아일랜드</span>
                    <div class="form-check form-check-inline ml-1">
                      <input class="form-check-input" type="radio" name="irelandRadioOptions" id="irelandRadio1"
                             value="없음" v-model="ireland">
                      <label class="form-check-label" for="irelandRadio1">없음</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="irelandRadioOptions" id="irelandRadio2"
                             value="4단" v-model="ireland">
                      <label class="form-check-label" for="irelandRadio2">4단</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="irelandRadioOptions" id="irelandRadio3"
                             value="5단" v-model="ireland">
                      <label class="form-check-label" for="irelandRadio3">5단</label>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">전신거울</span>
                    <div class="form-check form-check-inline ml-1">
                      <input class="form-check-input" type="radio" name="mirrorRadioOptions" id="mirrorRadio1"
                             value="있음" v-model="mirror">
                      <label class="form-check-label" for="mirrorRadio1">있음</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="mirrorRadioOptions" id="mirrorRadio2"
                             value="없음" v-model="mirror">
                      <label class="form-check-label" for="mirrorRadio2">없음</label>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">디바이드</span>
                    <div class="form-check form-check-inline ml-1">
                      <input class="form-check-input" type="radio" name="divideRadioOptions" id="divideRadio1"
                             value="있음" v-model="divide">
                      <label class="form-check-label" for="divideRadio1">있음</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="divideRadioOptions" id="divideRadio2"
                             value="없음" v-model="divide">
                      <label class="form-check-label" for="divideRadio2">없음</label>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend w-100">
                    <span class="input-group-text">이불장</span>
                    <div class="form-check form-check-inline ml-1">
                      <input class="form-check-input" type="radio" name="cabinetRadioOptions" id="cabinetRadio1"
                             value="있음" v-model="cabinet">
                      <label class="form-check-label" for="cabinetRadio1">있음</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="cabinetRadioOptions" id="cabinetRadio2"
                             value="없음" v-model="cabinet">
                      <label class="form-check-label" for="cabinetRadio2">없음</label>
                    </div>
                  </div>
                </div>
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">도면 파일</span>
                  </div>
                  <div class="custom-file">
                    <input ref="planFileUploader" id="planFile" type="file" class="custom-file-input"
                           accept=".skp" @change="upload($event, 0)"/>
                    <label class="custom-file-label" for="planFile">{{ planFileName }}</label>
                  </div>
                </div>

              </div>
              <div class="col-md-6 col-sm-12 board-write">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <span class="input-group-text">시공 이미지</span>
                  </div>
                  <div class="custom-file">
                    <input ref="dressRoomImgUploader" id="dressRoomImgFile" type="file" class="custom-file-input"
                           accept="image/*" multiple @change="upload($event, 1)"/>
                    <label class="custom-file-label" for="dressRoomImgFile" style="color: #999;">최대 3개까지 등록 가능</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4 text-center" v-for="(img, index) in dressRoomImg" :key="index">
                    <img :src="img.url" alt="시공 이미지" class="img-thumbnail">
                    <div class="row d-flex img-name-box">
                      <p class="pt-1 text-truncate mr-auto">{{ img.name }}</p>
                      <button type="button" class="close btn-close ml-auto" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="d-flex mt-3 mb-3">
                  <input type="button" class="btn btn-secondary ml-auto mr-2" value="취소" @click="$router.back()"/>
                  <input type="button" class="btn btn-success mr-auto" value="등록" @click="register()"/>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div><!-- /.container-fluid -->
    </div>

  </div>
</template>

<script>
export default {
  name: "PlanRegister",
  path: '/plan/register',
  data(){
    return {
      type: '',
      size1: '',
      size2: '',
      size3: '',
      size4: '',
      drawer: '',
      ireland: '',
      mirror: '',
      divide: '',
      cabinet: '',
      planFile: null,
      planFileName: '',
      dressRoomImg: [
        {
          file: null,
          name: '',
          url: '',
        }
      ]
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'plan', 'manage')
  },
  methods: {
    register() {
      if (this.size1 === '' && this.size2 === '' && this.size3 === '' && this.size4 === '') {
        this.notify('error', '치수를 1개 이상 입력해주세요.')
        return
      }

      if (this.planFile === null) {
        this.notify('error', '도면 파일을 등록해주세요')
        return
      }

      let formData = new FormData()
      formData.append('type', this.type)
      if (this.info.size1 !== ''){
        formData.append('size1', this.size1)
      }
      if (this.info.size2 !== ''){
        formData.append('size2', this.size2)
      }
      if (this.info.size3 !== ''){
        formData.append('size3', this.size3)
      }
      if (this.info.size4 !== ''){
        formData.append('size4', this.size4)
      }
      formData.append('drawer', this.drawer)
      formData.append('ireland', this.ireland)
      formData.append('mirror', this.mirror)
      formData.append('divide', this.divide)
      formData.append('cabinet', this.cabinet)
      formData.append('planFile', this.planFile)

      if (this.dressRoomImg.length > 0) {
        for (let i = 0; i < this.dressRoomImg.length; i++) {
          formData.append('detailImg[]', this.dressRoomImg[i].file)
        }
      }
      this.$post(this.$SETTING_MANAGER_REGISTER, this.$options.name + '_register', formData, () => {
        this.notify('success', '성공적으로 등록되었습니다.')
        this.$router.back()

      }, (result) => {
        this.httpError(result)
      })
    },
    upload(e, _type) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }

      if (_type === 0) { /*도면 파일*/
        this.planFile = files[0]
        this.planFileName = files[0].name

        this.$refs.planFileUploader.value = ''

      } else { /*시공 이미지*/
        if (this.dressRoomImg.length + files.length > 3) {
          this.notify('error', '시공이미지는 3장까지 등록 가능합니다')
          return
        }

        for (let i = 0; i < files.length; i++) {
          let url = URL.createObjectURL(files[i])
          this.dressRoomImg.push({'file': files[i], 'name': files[i].name, 'url': url})
        }

        this.$refs.dressRoomImgUploader.value = ''
      }
    },
  },
  watch: {
    size1() {
      if (!(/^[0-9]*$/.test(this.size1))) {
        this.size1 = /([0-9]*)/.exec(this.size1)[0]
      }
    },
    size2() {
      if (!(/^[0-9]*$/.test(this.size2))) {
        this.size2 = /([0-9]*)/.exec(this.size2)[0]
      }
    },
    size3() {
      if (!(/^[0-9]*$/.test(this.size3))) {
        this.size3 = /([0-9]*)/.exec(this.size3)[0]
      }
    },
    size4() {
      if (!(/^[0-9]*$/.test(this.size4))) {
        this.size4 = /([0-9]*)/.exec(this.size4)[0]
      }
    },
  },
}
</script>

<style scoped>

</style>