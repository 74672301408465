<template>
  <nav id="header" class="main-header navbar navbar-expand"
       :class="{'navbar-dark' : isDarkMode, 'navbar-white navbar-light' : !isDarkMode}">

    <button id="toggleBtn" class="btn" @click="showSideBar()" type="button"> <!-- :class="{ 'd-none' : grade === 'U' }" -->
      <span><i class="fas fa-bars"></i></span>
    </button>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <li class="nav-item nav-link d-none d-sm-inline-block">
        <p class="custom-control custom-switch">
          <i class="fas fa-sun mr-5"></i>
          <input type="checkbox" class="custom-control-input" id="customSwitch1" @change="changeMode()"
                 v-model="isDarkMode">
          <label class="custom-control-label" for="customSwitch1"></label>
          <i class="fas fa-moon"></i>
        </p>
      </li>
      <li @click=logout() class="nav-item nav-link text-orange" style="cursor: pointer">
        <p><i class="fas fa-sign-out-alt"></i></p>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      data: [],
      isDarkMode: false,
      isShowSideBar: true,
      grade: '',
    }
  },
  components: {},
  created() {
    if (localStorage.getItem('isDarkMode') === '1') {
      this.isDarkMode = true
    } else {
      this.isDarkMode = false
    }
  },
  mounted() {
    // this.grade = sessionStorage.getItem('grade')
    //
    // if (this.grade === 'U'){  /*일반 사용자*/
    //   document.getElementById('wrapper').style.marginLeft = '0'
    //   document.getElementById('header').style.marginLeft = '0'
    //   document.getElementById('mainSideBar').style.marginLeft = '-250px'
    //
    //   this.isShowSideBar = false
    //
    // } else {
      if (localStorage.getItem('sidebar') === 'true') {
        document.getElementById('wrapper').style.marginLeft = '250px'
        document.getElementById('header').style.marginLeft = '250px'
        document.getElementById('mainSideBar').style.marginLeft = '0'

        this.isShowSideBar = true

      } else {
        document.getElementById('wrapper').style.marginLeft = '0'
        document.getElementById('header').style.marginLeft = '0'
        document.getElementById('mainSideBar').style.marginLeft = '-250px'

        this.isShowSideBar = false
      }
    // }
  },
  methods: {
    logout() {
      this.$router.push({name: 'Login'})
    },
    changeMode() {
      if (localStorage.getItem('isDarkMode') === '1') {
        localStorage.setItem('isDarkMode', '0')
        this.isDarkMode = false
        this.emitter.emit(this.$EVENT_MODE_CHANGE)
      } else {
        localStorage.setItem('isDarkMode', '1')
        this.isDarkMode = true
        this.emitter.emit(this.$EVENT_MODE_CHANGE)
      }
    },
    showSideBar() {
      if (this.isShowSideBar) {
        document.getElementById('wrapper').style.marginLeft = '0'
        document.getElementById('header').style.marginLeft = '0'
        document.getElementById('mainSideBar').style.marginLeft = '-250px'

        this.isShowSideBar = false
        localStorage.setItem('sidebar', this.isShowSideBar)

      } else {
        document.getElementById('wrapper').style.marginLeft = '250px'
        document.getElementById('header').style.marginLeft = '250px'
        document.getElementById('mainSideBar').style.marginLeft = '0'

        this.isShowSideBar = true
        localStorage.setItem('sidebar', this.isShowSideBar)
      }
    },
  }
}
</script>

<style>
</style>
