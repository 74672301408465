<template>
  <div id="indicator" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="d-flex justify-content-center spinner">
      <div class="spinner-border text-primary" role="status">
        <!--      <span class="visually-hidden">Loading...</span>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Indicator",
  methods: {
    showIndicator(){
      window.$('#indicator').modal('show');
    },
    close(){
      window.$('#indicator').modal('hide');
    }
  }
}
</script>

<style scoped>

</style>