<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">관리자 계정 관리</h4>
            <span>관리자 계정을 관리합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
        <div class="row mb-2">
          <div class="col-sm-12">
            <div class="row">
              <div class="ml-auto">
                <button class="btn btn-success" @click="goRegister()">계정 등록</button>
              </div>
            </div>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">계정 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <table class="table table-bordered" id="excelData">
                  <thead>
                  <tr>
                    <th>No</th>
                    <th>아이디</th>
                    <th>메모</th>
                    <th>사용</th>
                    <th>수정</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td>{{ item.no }}</td>
                    <!--                    <td>{{ totalCount - ((page - 1) * size) - index }}</td>-->
                    <td>{{ item.id }}</td>
                    <td>{{ item.memo }}</td>
                    <td class="position-relative">
                      <input class="position-absolute" type="checkbox" :checked="item.deleted === 0">
                      <div class="btn-check"></div>
                    </td>
                    <td class="text-center">
                      <span class="badge btn btn-outline-warning" @click="goEdit()">수정</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div><!-- ./card-body -->
              <div class="card-footer clearfix">
                <pagination ref="Pagination"></pagination>
              </div>
            </div><!-- /.card -->
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div>
    </section>
    <div class="modal fade" id="adminAddModal" tabindex="-1" aria-labelledby="adminAddModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="adminAddModalLabel">계정 등록</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 메모 외 항목은 필수입니다.</p>
            <div class="input-group pb-1">
              <input type="text" class="form-control" v-model.trim="addId" placeholder="*아이디">
            </div>
            <div class="input-group pb-1">
              <input type="password" class="form-control" v-model.trim="addPw" placeholder="*비밀번호">
            </div>
            <div class="input-group pb-1">
              <input type="text" class=" form-control" v-model.trim="addMemo" placeholder="메모">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="등록" @click="register()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="adminEditModal" tabindex="-1" aria-labelledby="adminEditModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="adminEditModalLabel">계정정보 수정 ({{ editId }})</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p class="text-danger">* 수정하실 항목을 입력해주세요</p>
            <div class="input-group pb-1">
              <input type="password" class=" form-control" v-model.trim="editPw" placeholder="*비밀번호">
            </div>
            <div class="input-group pb-1">
              <input type="text" class=" form-control" v-model.trim="editMemo" placeholder="메모">
            </div>
          </div>
          <div class="modal-footer">
            <input type="button" class="btn btn-info" value="저장" @click="edit()"/>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">취소</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/common/Pagination";

export default {
  name: "AdminAccount",
  path: "/admin/account",
  components: {
    Pagination
  },
  data() {
    return {
      items: [
        {
          no: 1,
          id: '아이디1',
          memo: '메모1',
          deleted: 0,
        },
        {
          no: 2,
          id: '아이디2',
          memo: '메모2',
          deleted: 1,
        }
      ],
      size: 10,
      page: 1,
      totalCount: 0,

      addId: '',
      addPw: '',
      addMemo: '',

      /*수정*/
      id: 0,
      editId: '',
      editPw: '',
      editMemo: '',
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'admin', 'account')
  },
  mounted() {
    // if (this.$route.query.page) {
    //   this.page = this.$route.query.page
    // }
    // if (this.$route.query.size) {
    //   this.size = this.$route.query.size
    // }
    //
    // this.getList(this.page)
  },
  methods: {
    // getList(_page) {
    //   this.$get(this.$BUSINESS_ACCOUNT + '?page=' + _page + '&perPage=' + this.size, this.$options.name + '_getList', (result) => {
    //     this.items = result.data.data
    //     this.totalCount = result.data.total
    //
    //     this.page = _page
    //
    //     /* 페이지네이션 */
    //     try {
    //       this.$refs.Pagination.init(this.$options.name, null, {}, this.page, this.totalCount, Number(this.size))
    //
    //     } catch (e) {
    //       console.log(e)
    //     }
    //
    //   }, (result) => {
    //     this.httpError(result)
    //   })
    // },
    goRegister() {
      this.addId = ''
      this.addPw = ''
      this.addMemo = ''

      window.$('#adminAddModal').modal('show');
    },
    register() {
      // if (this.addId === '') {
      //   this.notify('error', '아이디를 입력해주세요.')
      //   return
      // }
      // if (this.addPw === '') {
      //   this.notify('error', '비밀번호를 입력해주세요.')
      //   return
      // }
      //
      // let queryString = '?id=' + this.addId + '&password=' + encodeURIComponent(this.addPw)
      // if (this.addMemo !== '') {
      //   queryString = queryString + '&memo=' + this.addMemo
      // }
      //
      // this.$post(this.$BUSINESS_ACCOUNT_REGISTER + queryString, this.$options.name + '_register', null, () => {
      //   this.notify('success', '성공적으로 등록되었습니다.')
      //   window.$('#adminAddModal').modal('hide');
      //   this.getList(1)
      //
      // }, (result) => {
      //   this.httpError(result)
      // })
    },
    goEdit() {
      this.id = ''
      this.editId = ''
      this.editPw = ''
      this.editMemo = ''

      window.$('#adminEditModal').modal('show');
    },
    edit() {
      // if (this.editPw === '' && this.editMemo === '') {
      //   this.notify('error', '수정하실 항목을 입력해주세요.')
      //   return
      // }
      //
      // let queryString = ''
      // if (this.editPw !== '') {
      //   queryString = queryString + '&password=' + encodeURIComponent(this.editPw)
      // }
      // if (this.editMemo !== '') {
      //   queryString = queryString + '&memo=' + this.editMemo
      // }
      //
      // queryString = queryString.replace('&', '?')
      //
      // this.$put(this.$BUSINESS_ACCOUNT_EDIT + this.id + queryString, this.$options.name + '_edit', null, () => {
      //   this.notify('success', '성공적으로 변경되었습니다.')
      //   window.$('#adminEditModal').modal('hide');
      //   this.getList(this.page)
      //
      // }, (result) => {
      //   this.httpError(result)
      // })
    }
  },
  watch: {
    '$route.query.page'() {
      if (this.$route.query.page) {
        // this.getList(this.$route.query.page)
      } else {
        // this.getList(1)
      }
    },
  }
}
</script>

<style scoped>

</style>