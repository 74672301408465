<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-logo">
        <a><b>드레스룸 도면</b></a>
      </div>
      <!-- /.login-logo -->
      <div class="card">
        <div class="card-body login-card-body text-center">
          <!--          <p class="login-box-msg">아이디와 비밀번호를 입력해주세요</p>-->
          <div class="input-group mb-3">
            <input type="email" class="form-control" placeholder="Email" v-model.trim="email">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-id-badge"></span>
              </div>
            </div>
          </div>
          <div class="input-group mb-3">
            <input type="password" class="form-control" placeholder="Password" v-model.trim="password"
                   @keyup.enter="login()">
            <div class="input-group-append">
              <div class="input-group-text">
                <span class="fas fa-lock"></span>
              </div>
            </div>
          </div>

          <button class="btn btn-primary" @click="login()">로그인</button>
        </div>
        <!-- /.login-card-body -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Login',
  path: '/',
  meta: {
    hideLNBHeader: true
  },
  components: {},
  data() {
    return {
      email: '',
      password: '',
    }
  },
  mounted() {
    if (localStorage.getItem('sidebar') === 'true') {
      document.getElementById('wrapper').style.marginLeft = '0'
    }
  },
  created() {
    sessionStorage.removeItem('token')
  },
  methods: {
    login() {
      // if (this.email === '') {
      //   this.notify('error', '이메일을 입력해주세요.')
      //   return
      // }
      // if (this.password === '') {
      //   this.notify('error', '비밀번호를 입력해주세요.')
      //   return
      // }
      //
      // this.$post(this.$AUTH + '?email=' + this.email + '&password=' + encodeURIComponent(this.password), this.$options.name + '_login', null, (result) => {
      //   sessionStorage.setItem('token', result.data.access_token)

      // test start
      // 최고관리자(CA), 관리자(A), 일반유저(U)
      // let grade = 'CA'
      // let grade = 'A'
      // let grade = 'U'

      // sessionStorage.setItem('grade', grade)
      // this.emitter.emit(this.$EVENT_SET_LNB)

      // 사용자 타입에 따라 진입화면 구분
      // if (grade === 'CA'){
        this.$router.replace({name: 'AdminAccount'})
      // } else if (grade === 'A'){
      //   this.$router.replace({name: 'PlanManage'})
      // } else if (grade === 'U'){
      //   this.$router.replace({name: 'PlanList'})
      // }

      // test end

      // }, (result) => {
      //   this.httpError(result)
      // })
    }
  }
}
</script>

<style>
.dark-mode .login-page {
  background-color: #454d55 !important;
}
</style>
