<template>
  <div>
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-4">
          <div class="col-sm-12">
            <h4 class="m-0">도면 상세</h4>
            <span>도면 상세 내용을 확인합니다.</span>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="row">

          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">시공이미지 목록</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-4 text-center">
                          <img src="img/dressroom.jpg" alt="시공 이미지" class="img-thumbnail">
                          <p class="pt-1 text-truncate">이미지.jpg</p>
                        </div>

                        <div class="col-sm-12 col-md-4 text-center">
                          <img src="img/dressroom.jpg" alt="시공 이미지" class="img-thumbnail">
                          <p class="pt-1 text-truncate mr-auto">이미지.jpg</p>
                        </div>

                        <div class="col-sm-12 col-md-4 text-center">
                          <img src="img/dressroom.jpg" alt="시공 이미지" class="img-thumbnail">
                          <p class="pt-1 text-truncate mr-auto">이미지.jpg</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->

          <div class="col-md-6">
            <div class="card">
              <div class="card-header">
                <h5 class="card-title">도면 상세 정보</h5>
              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="m-3">
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>타입</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>_</span>
                        </div>
                      </div>
                      <div class="mb-2 border-bottom"></div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수1</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>2600</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수2</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>1300</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수3</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>2800</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>치수4</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>900</span>
                        </div>
                      </div>
                      <div class="mb-2 border-bottom"></div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>서랍</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>없음/2단/3단/4단/5단</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>아일랜드</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>없음/4단/5단</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>전신거울</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>있음/없음</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>디바이드</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>있음/없음</span>
                        </div>
                      </div>
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>이불장</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>있음/없음</span>
                        </div>
                      </div>

                      <div class="mb-2 border-bottom"></div>
                      <!-- 도면 이미지 -->
                      <div class="row align-items-center mb-2">
                        <div class="col-sm-3 col-3 text-right">
                          <strong>도면 파일</strong>
                        </div>
                        <div class="col-sm-6 col-6">
                          <span>도면.skp</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div><!-- ./card-body -->
            </div><!-- /.card -->
          </div><!-- /.col -->

          <div class="col-12">
            <div class="d-flex mt-3 mb-3">
              <input type="button" class="btn btn-secondary ml-auto mr-auto" value="목록으로" @click="$router.back()"/>
            </div>
          </div>

        </div><!-- /.row -->
      </div>
    </section>
  </div>
<!--  <a href="https://www.flaticon.com/kr/free-icons/" title="사양 아이콘">사양 아이콘  제작자: Circlon Tech - Flaticon</a>-->
</template>

<script>
export default {
  name: "PlanDetail",
  path: '/plan/detail',
  data(){
    return {
      type: 'ㄱ',
      size1: '',
      size2: '',
      size3: '',
      size4: '',
      drawer: '없음',
      ireland: '없음',
      mirror: '있음',
      divide: '있음',
      cabinet: '있음',
      planFile: '도면 파일 링크',
      dressRoomImg: [
        {
          id: 1,
          path: '이미지 경로',
          name: '이미지 이름',
        }
      ],
    }
  },
  created() {
    this.emitter.emit(this.$EVENT_LNB_CHANGE, 'user_plan', 'list')
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      // this.$get(this.$SETTING_MANAGER_DETAIL + this.$route.query.id, this.$options.name + '_getData', (result) => {
      //   this.type = result.data.type
      //   this.size1 = result.data.size1
      //   this.size2 = result.data.size2
      //   this.size3 = result.data.size3
      //   this.size4 = result.data.size4
      //   this.drawer = result.data.drawer
      //   this.ireland = result.data.ireland
      //   this.mirror = result.data.mirror
      //   this.divide = result.data.divide
      //   this.cabinet = result.data.cabinet
      //   this.ireland = result.data.ireland
      //
      //   /*도면파일*/
      //   let planFile = result.data.planFile.split('/')
      //   this.planFileName = planFile[planFile.length - 1]
      //
      //   this.dressRoomImg = result.data.dressRoomImg
      //
      // }, (result) => {
      //   this.httpError(result)
      // })
    },
  }
}
</script>

<style scoped>

</style>