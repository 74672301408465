export default Object.freeze({
    install(Vue) {
        Vue.config.globalProperties.numberWithCommas = function (number) {
            if (!(number + '').includes('.')) {
                return (number + '').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            } else {
                return `${((number + '').split('.')[0]).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${(number + '').split('.')[1]}`
            }
        }

        // Vue.config.globalProperties.qnaState = function (status) {
        //     if (status === 1) {
        //         return '답변대기'
        //     } else if (status === 2) {
        //         return '답변완료'
        //     }
        //     return ''
        // }
    }
})
